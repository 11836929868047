import { apiUrl } from './api';
import { sendPostRequest } from './requestController';

export const getReqdElasticDataFields = type => {
  let reqdfields: Array<string> = [];
  switch (type) {
    case 'ticket':
      reqdfields = [
        'archived',
        'attachments',
        'audits',
        'content',
        'couchDbId',
        'database',
        'dates',
        'groupId',
        'location',
        'map',
        'participants',
        'project',
        'state',
        'tags',
        'plan',
        '_id',
        'plan',
        'isSVG',
        'isProofMandatory',
      ];
      break;
    case 'ticket-suggestion':
      reqdfields = ['archived', 'content.title', 'content.body', 'couchDbId', 'database', 'state'];
      break;
    case 'ticket-map':
      reqdfields = [
        'archived',
        'attachments',
        'content',
        'couchDbId',
        'database',
        'dates',
        'groupId',
        'location',
        'map',
        'participants',
        'project',
        'state',
        'plan',
        '_id',
        'isSVG',
      ];
      break;
    case 'audit-ticket-select':
      reqdfields = ['archived', 'attachments', 'content', 'couchDbId', 'map', 'participants', 'state', '_id', 'groupId', 'database'];
      break;
    case 'auditTemplate-import':
      reqdfields = ['couchDbId', 'groupId', 'isPublished', 'group', 'name'];
      break;
    case 'ticket-geo':
      reqdfields = ['archived', 'couchDbId', 'map', 'participants', 'location', 'state', '_id'];
      break;
    case 'aggregate':
      reqdfields = ['couchDbId'];
      break;
    case 'audits':
      reqdfields = ['archived', 'author', 'couchDbId', 'dates', 'groupId', 'maps', 'name', 'participants', 'status', 'signature', 'questions', 'template', 'database', 'tags'];
      break;
    case 'auditTemplate':
      reqdfields = ['archived', 'couchDbId', 'author', 'database', 'dates', 'groupId', 'isPublished', 'group', 'name', 'lastmodifier', 'auditType', 'tags', '_id'];
      break;
    case 'mapGroupBased':
      reqdfields = ['couchDbId', 'archived', 'database', 'group', 'groupId', 'name', 'participants'];
      break;
    case 'library':
      reqdfields = [
        'couchDbId',
        'archived',
        'content',
        'contentType',
        'fileGroupId',
        'dates',
        'fileMetaData',
        'group',
        'groupId',
        'name',
        'type',
        'tileStatus',
        'deleted',
        'tags',
        'versionId',
        'fileId',
        'sourceName',
        'fileGroupID',
        'fileInfo',
        'operation',
        'attachments',
      ];
      break;
    case 'library-selectAll':
      reqdfields = ['couchDbId', 'type', 'archived', 'tileStatus', 'name', 'versionId', 'fileMetaData', 'fileId', 'attachments'];
      break;
    case 'selectAll':
      reqdfields = ['couchDbId', 'database', 'archived', 'state', 'map', 'dates', 'status', 'name', 'isProofMandatory', 'participants'];
      break;
    case 'mapReporter':
      reqdfields = ['couchDbId', 'name', 'participants', 'archived', 'group', 'groupId', 'dates', 'tags', 'lastmodifier', 'content', 'type'];
      break;
    case 'audit-ticket':
      reqdfields = ['couchDbId', 'name', 'groupId', 'template'];
      break;
    case 'ticket-audit-list':
      reqdfields = ['archived', 'author', 'couchDbId', 'dates', 'maps', 'name', 'participants', 'status', 'signature', 'questions', 'groupId', 'template', 'database'];
      break;
    default:
      break;
  }
  return reqdfields;
};

export const saveBulk = (data, databaseId) => {
  const docs = { docs: {} };
  const url = `${apiUrl.couchDBUrl}${databaseId}/_bulk_docs`;
  docs.docs = data;
  return new Promise(resolve => {
    sendPostRequest(url, docs).then(response => {
      return resolve(response);
    });
  });
};

export const getBulkDoc = (ids, databaseId, shouldGetAttachments = false) => {
  let url = `${apiUrl.couchDBUrl}${databaseId}/_all_docs?include_docs=true`;
  if (shouldGetAttachments) {
    url += '&attachments=true';
  }
  const postData = { keys: [] };
  postData.keys = ids;
  return new Promise(resolve => {
    sendPostRequest(url, postData).then(data => {
      return resolve(data);
    });
  });
};

// Update bulk document
export const updateBulkDoc = async (ids, keys, database) => {
  const bulkData: any = await getBulkDoc(ids, database);
  let dataUpdate: any = [];
  bulkData.rows.forEach((_info, index) => {
    keys.forEach(info => {
      Object.keys(info).forEach(function (key) {
        const value = info[key];
        const val: any = value;
        if (key === 'archive_ticket') {
          return;
        }
        if (key === 'import_template') {
          //this will delete the id and rev when imported
          delete bulkData.rows[index].doc._id;
          delete bulkData.rows[index].doc._rev;
          delete bulkData.rows[index].doc.signature;
          delete bulkData.rows[index].doc.status;
          if (!bulkData.rows[index].doc.auditType) {
            bulkData.rows[index].doc.auditType = 'area';
          }
          bulkData.rows[index].doc.timeline = [];
        } else if (key === 'timeline') {
          if (!bulkData.rows[index].doc.timeline) {
            bulkData.rows[index].doc.timeline = [];
          }
          if (info.archive_ticket) {
            bulkData.rows[index].doc.timeline.push(val[bulkData.rows[index].doc._id]);
            return;
          }
          if (bulkData.rows[index].doc[key]) {
            bulkData.rows[index].doc[key] = bulkData.rows[index].doc[key].concat(val);
          } else {
            bulkData.rows[index].doc[key] = {};
            bulkData.rows[index].doc[key] = val;
          }
        } else if (key === 'operation') {
          if (!bulkData.rows[index].doc.operation) {
            bulkData.rows[index].doc.operation = [];
          }
          if (info.archive_ticket) {
            bulkData.rows[index].doc.operation.push(val[bulkData.rows[index].doc._id]);
            return;
          }
          if (bulkData.rows[index].doc[key]) {
            bulkData.rows[index].doc[key] = bulkData.rows[index].doc[key].concat(val);
          } else {
            bulkData.rows[index].doc[key] = {};
            bulkData.rows[index].doc[key] = val;
          }
        } else if (key === 'lastModifiedDate') {
          if (bulkData.rows[index].doc.dates) {
            bulkData.rows[index].doc.dates.lastModifiedDate = val;
          } else {
            bulkData.rows[index].doc.dates = {};
            bulkData.rows[index].doc.dates.lastModifiedDate = val;
          }
        } else {
          bulkData.rows[index].doc[key] = val;
        }
      });
      dataUpdate.push(bulkData.rows[index].doc);
    });
  });
  const docs: any = {};
  docs.docs = dataUpdate;
  const url = `${apiUrl.couchDBUrl}${database}/_bulk_docs`;
  return new Promise(resolve => {
    sendPostRequest(url, docs).then(() => {
      resolve(true);
    });
  });
};
