/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import userGuide from './userGuideData';
import { AppStateContext } from '../..';
import { EDStateContext } from '../../context/EdState';

import './UserGuide.scss';

import closeIcon from '../../images/close_icon.svg';
import rightIcon from '../../images/right-arrow.svg';

type UserGuideProps = {
  type: string;
  databaseId: string;
  onClose?: () => void;
};

const UserGuide = ({ type, onClose, databaseId }: UserGuideProps) => {
  const navigate = useNavigate();
  const userGuideData = useRef(userGuide[type]);
  const { language: lang } = useContext(AppStateContext);
  const { freeTrailGuideData, stateUpdater } = useContext(EDStateContext);
  const [showButton, setShowButton] = useState(true);
  const location = useLocation();

  useEffect(() => {
    getSetPopupLocation();
  }, []);

  /**
   * @function removeClass
   * @description Function to remove the class from the user guide class from DOM
   * @param className Extra classname which needs to be removed from DOM along with default class
   * @author gaurav.rao
   */
  const removeClass = (className?: string) => {
    const mainElements = document.querySelectorAll(`.user-guide-highlight`);
    mainElements.forEach(element => {
      element.classList.remove(`user-guide-highlight`);
    });

    if (className) {
      const elements = document.querySelectorAll(`.${className}`);
      elements.forEach(element => {
        element.classList.remove(`${className}`);
      });
    }
  };

  const positionPopup = (top?: number, left?: number | string, right?: number | string, bottom?: number | string) => {
    const node = document.getElementById('user-guide').parentElement;

    node.style.position = 'fixed';
    if (top) {
      node.style.top = top + 'px';
    }
    if (right) {
      node.style.right = right + 'px';
    }
    if (left) {
      node.style.left = left === 'auto' ? left : left + 'px';
    }
    if (bottom) {
      node.style.bottom = bottom + 'px';
    }
    const parentDom = document.getElementById('user-guide');
    if (parentDom) {
      parentDom.classList.add('show');
    }
  };

  const getSetPopupLocation = () => {
    let element, clientRect, editableElement, bothElement;
    const leftOffset = 40,
      rightOffset = 590;
    if (!freeTrailGuideData.isShowFlow && freeTrailGuideData.isGuideSkipped) {
      onClose();
      return;
    }

    switch (freeTrailGuideData.type) {
      case 'project':
      case 'ticket':
      case 'ticketChooseStep':
      case 'libraryIntro':
      case 'audit':
      case 'auditCreated':
      case 'reporterCreated':
      case 'auditPageTwoAnsQues':
      case 'auditPageOneAddDrawing':
      case 'auditCompleted':
      case 'templateImportSuccess':
      case 'reporter':
      case 'reporterTicketCreated':
        positionPopup();
        break;
      case 'libraryUpload':
        positionPopup();
        removeClass();
        break;
      case 'libraryGroup':
        setTimeout(() => {
          element = document.getElementById('left-menu');
          editableElement = document.getElementById('add-new-group-library');
          if (editableElement) {
            editableElement.classList.add('user-guide-highlight', 'user-guide-highlight--small');
          }
          if (element) {
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
          }
        }, 1000);
        break;
      case 'libraryMap':
        setTimeout(() => {
          element = document.getElementById('select-option');
          editableElement = document.getElementById('select-filepicker');
          if (editableElement) {
            removeClass('user-guide-highlight--small');
            editableElement.classList.add('user-guide-highlight');
          }
          if (element) {
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'libraryNotification':
        setTimeout(() => {
          element = document.getElementById('main-header-notification');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top + 60, 0, leftOffset);
          }
        }, 1000);
        break;
      case 'librarySelectDrawing':
        setTimeout(() => {
          element = document.getElementById('library-document');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--ml15');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top + 120, 1);
          }
        }, 1000);
        break;
      case 'ticketCreate':
        setTimeout(() => {
          element = document.getElementById('new-ticket');
          if (element) {
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'ticketSelect':
        positionPopup(0, 'auto', 10, 10);
        break;
      case 'ticketComments':
        setTimeout(() => {
          element = document.getElementById('ticket-detail-comments-container');
          if (element) {
            element.classList.add('user-guide-highlight', 'user-guide-highlight--small');
            element.scrollIntoView();
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left, 0, 350);
          }
        }, 1000);
        break;
      case 'respnsibleTicketStatusChange':
        setTimeout(() => {
          element = document.getElementById('ticket_status_guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--w50');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'rciCommentAdded':
      case 'informedCheckDetails':
      case 'reporterCheckDetails':
      case 'auditInformedCheckDetails':
        setTimeout(() => {
          removeClass();
          positionPopup(0, 'auto', 30, 20);
        }, 1000);
        break;
      case 'ticketAssign':
        setTimeout(() => {
          element = document.getElementById('ticket-assign-group');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--large');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'ticketStatusChange':
        setTimeout(() => {
          element = document.getElementById('ticket_status_guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--w50');
            element.scrollIntoView();
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'ticketArchive':
        setTimeout(() => {
          element = document.getElementById('ticket_archive_guide');
          bothElement = document.getElementById('ticket_save_guide');
          if (element && bothElement) {
            removeClass();
            element.classList.add('user-guide-highlight');
            bothElement.classList.add('user-guide-highlight');
            element.scrollIntoView();
            bothElement.scrollIntoView();
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top - 120, clientRect.left - rightOffset);
            setShowButton(false);
          }
        }, 1000);
        break;
      case 'ticketLocate':
        setTimeout(() => {
          element = document.getElementById('map-container');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'map-user-guide');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, 10);
          }
        }, 2000);
        break;
      case 'ticketCreated':
        removeClass('map-user-guide');
        positionPopup();
        break;
      case 'ticketFindDraw':
      case 'reporterTicketCreation':
        setTimeout(() => {
          element = document.getElementById('ticketMaps-guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--leftMenu');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
          }
        }, 100);
        break;
      case 'goToAudit':
        setTimeout(() => {
          element = document.getElementById('ed-audits');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top + 50);
          }
        }, 1000);
        break;
      case 'selectTemplate':
        setTimeout(() => {
          element = document.getElementById('template-selection');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
          }
        }, 1000);
        break;
      case 'startAudit':
        setTimeout(() => {
          element = document.getElementById('add-audit');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, 'auto', 200, 20);
          } else {
            positionPopup();
          }
        }, 2000);
        break;
      case 'auditGroup':
        setTimeout(() => {
          element = document.getElementById('template-selection');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
          }
        }, 1000);
        break;
      case 'auditTemplateSelect':
        setTimeout(() => {
          element = document.getElementById('template-selection');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--leftMenu');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
          }
        }, 100);
        break;
      case 'auditSelect':
        setTimeout(() => {
          removeClass();
          positionPopup(0, 0, 10, 130);
        }, 1000);
        break;
      case 'auditPageOneEditName':
        setTimeout(() => {
          element = document.getElementById('ad-name-dbclick');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top + 70, clientRect.left, 0, 0);
          }
        }, 1000);
        break;
      case 'auditPageOneAddDetails':
        setTimeout(() => {
          element = document.getElementById('audit_email_guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--large');
            element.scrollIntoView();
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left, 0, clientRect.top + 190);
          }
        }, 1000);
        break;
      case 'auditPageOneSave':
        setTimeout(() => {
          element = document.getElementById('ad-save-edit');
          bothElement = document.getElementById('ad-btn-next');
          if (element && bothElement) {
            removeClass();
            element.scrollIntoView();
            element.classList.add('user-guide-highlight');
            bothElement.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left - rightOffset, 0, 30);
          }
        }, 1000);
        break;
      case 'auditPageTwoSave':
        setTimeout(() => {
          element = document.getElementById('ad-save-edit');
          if (element) {
            removeClass();
            element.scrollIntoView();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left - rightOffset, 0, 30);
          }
        }, 1000);
        break;
      case 'auditResInfoPageOneNext':
        setTimeout(() => {
          element = document.getElementById('ad-btn-next');
          if (element) {
            removeClass();
            element.scrollIntoView();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.left - rightOffset, 0, 30);
          }
        }, 1000);
        break;
      case 'templateGroup':
        setTimeout(() => {
          editableElement = document.getElementById('template-new-group');
          element = document.getElementById('left-menu');
          const scrollElement = document.getElementById('left-menu-scrollbar');
          if (editableElement) {
            removeClass();
            editableElement.classList.add('user-guide-highlight', 'user-guide-highlight--leftMenu');
            clientRect = element.getBoundingClientRect();
            positionPopup(0, clientRect.right + leftOffset);
            if (scrollElement.scrollTop > 200) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
              editableElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
          }
        }, 1000);
        break;
      case 'templateCreate':
        setTimeout(() => {
          element = document.getElementById('ed-butn-new_template_div');
          if (element) {
            removeClass('user-guide-highlight--small');
            element.classList.add('user-guide-highlight', 'user-guide-highlight--removeBorder');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          } else {
            positionPopup();
          }
        }, 1000);
        break;
      case 'templateName':
        setTimeout(() => {
          element = document.getElementById('temp-name-guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--removeBorder');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top - 120, clientRect.right + 20, 0, 0);
          }
        }, 1000);
        break;
      case 'templateAuditType':
        setTimeout(() => {
          element = document.getElementById('audit-type-guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--removeBorder');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top - 120, clientRect.right + 20, 0, 0);
          }
        }, 1000);
        break;
      case 'templateAddCategoryName':
        setTimeout(() => {
          element = document.getElementById('temp_cat_name_guide');
          if (element) {
            removeClass();
            element.scrollIntoView();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--removeBorderPadding');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top - 320, 30, 0, 0);
          }
        }, 1000);
        break;
      case 'templateSaveDraftOrPublish':
        setTimeout(() => {
          element = document.getElementById('temp-save-guide');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--removeBorder');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, 0, clientRect.left - 500, 0);
          }
        }, 1000);
        break;
      case 'templateImport':
      case 'templateImportDraftOrPublish':
        removeClass();
        break;
      case 'reporterAdd':
        setTimeout(() => {
          element = document.getElementById('new-reporter');
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset);
          }
        }, 1000);
        break;
      case 'reporterMap':
        setTimeout(() => {
          element = document.getElementById('reporter-map-data');
          editableElement = document.getElementById('reporter-email-row');
          const ele = document.getElementById('reporter-cancel');
          if (ele) {
            ele.style.display = 'none';
          }
          if (element) {
            removeClass();
            element.classList.add('user-guide-highlight', 'user-guide-highlight--repscroll');
            editableElement.classList.add('user-guide-highlight');
            clientRect = element.getBoundingClientRect();
            positionPopup(clientRect.top, clientRect.left - rightOffset + 30);
          }
        }, 1000);
        break;
      default:
        break;
    }
  };

  const skipTuts = (guideType: string) => {
    removeClass('map-user-guide');
    removeClass('user-guide-highlight--small');
    removeClass('user-guide-highlight--leftMenu');
    const ele = document.getElementById('reporter-cancel');
    if (ele) {
      ele.style.display = 'block';
    }
    const data = {
      ...freeTrailGuideData,
      isShowFlow: false,
      isViewedOnce: true,
      isGuideSkipped: (guideType === 'startAudit' && location.pathname.includes('audits/new')) || guideType === 'auditPageOneAddDrawing' ? false : true,
    };
    stateUpdater({ type: 'userGuideData', payload: data });
    onClose();
  };

  const onNavigate = (guideType: string) => {
    const dbId = databaseId || freeTrailGuideData.databaseId;
    let ele = document.getElementById('td-save');
    if (guideType === 'ticketChooseStep') {
      const data = {
        ...freeTrailGuideData,
        type: 'libraryGroup',
        // link: 'maps',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      if (userGuideData.current.link) {
        setTimeout(() => {
          navigate({
            pathname: `/projects/${dbId}/${userGuideData.current.link}`,
          });
        });
      }
      return;
    }
    if (guideType === 'audit') {
      const data = {
        ...freeTrailGuideData,
        type: 'templateGroup',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      if (userGuideData.current.link) {
        setTimeout(() => {
          navigate({
            pathname: `/projects/${dbId}/${userGuideData.current.link}`,
          });
        });
      }
      return;
    }
    if (guideType === 'ticketCreated') {
      const data = {
        ...freeTrailGuideData,
        type: 'ticketSelect',
        link: 'tickets',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      if (data.link) {
        setTimeout(() => {
          navigate({
            pathname: `/projects/${dbId}/${data.link}`,
          });
        });
      }
      return;
    }
    if (guideType === 'auditPageOneEditName') {
      document.getElementById('ad-name-dbclick').classList.remove(`user-guide-highlight`);
    }
    if (
      guideType === 'ticketArchive' ||
      guideType === 'ticketSelect' ||
      guideType === 'rciCommentAdded' ||
      guideType === 'informedCheckDetails' ||
      guideType === 'reporterCheckDetails' ||
      guideType === 'auditSelect' ||
      guideType === 'auditInformedCheckDetails'
    ) {
      if (guideType === 'ticketArchive' || (freeTrailGuideData.guideRoles === 'responsible' && guideType === 'rciCommentAdded')) {
        if (ele) {
          document.getElementById('td-save').click();
        }
      }
      skipTuts(guideType);
    } else if (guideType === 'auditPageTwoSave') {
      document.getElementById('ad-save-edit').click();
      skipTuts(guideType);
    } else if (guideType === 'templateSaveDraftOrPublish' || guideType === 'templateImportSuccess' || guideType === 'reporterTicketCreated' || guideType === 'auditCompleted') {
      // document.getElementById("publish").click();
      skipTuts(type);
    } else {
      const data = {
        ...freeTrailGuideData,
        type: userGuideData.current.nextType,
        isViewedOnce: true,
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      if (userGuideData.current.link) {
        setTimeout(
          () => {
            navigate({
              pathname: `/projects/${dbId}/${userGuideData.current.link}`,
            });
          },
          userGuideData.current.nextType === 'audit' ? 500 : 0,
        );
      }
    }
    if (guideType === 'ticketComments' && freeTrailGuideData.guideRoles === 'responsible') {
      const data = {
        ...freeTrailGuideData,
        // type: 'respnsibleTicketStatusChange',
        type: 'ticketAssign',
        guideRoles: 'responsible',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    } else if (guideType === 'ticketComments' && freeTrailGuideData.guideRoles === 'consulted') {
      const data = {
        ...freeTrailGuideData,
        type: 'rciCommentAdded',
        guideRoles: 'consulted',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    }

    if (guideType === 'ticketAssign' && freeTrailGuideData.guideRoles === 'responsible') {
      const data = {
        ...freeTrailGuideData,
        type: 'respnsibleTicketStatusChange',
        guideRoles: 'responsible',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    } else if (guideType === 'ticketAssign') {
      const data = {
        ...freeTrailGuideData,
        type: 'ticketStatusChange',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    }
  };

  const onNavigateBack = (guideType: string) => {
    if (guideType === 'project') {
      skipTuts(guideType);
    } else {
      const data = {
        ...freeTrailGuideData,
        type: userGuideData.current.previousType,
        isViewedOnce: true,
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      removeClass();
    }

    if (guideType === 'respnsibleTicketStatusChange' && freeTrailGuideData.guideRoles === 'responsible') {
      const data = {
        ...freeTrailGuideData,
        type: 'ticketComments',
        guideRoles: 'responsible',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    } else if (guideType === 'rciCommentAdded' && freeTrailGuideData.guideRoles === 'consulted') {
      const data = {
        ...freeTrailGuideData,
        type: 'ticketComments',
        guideRoles: 'consulted',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    }
  };

  const onNavigateToNext = type => {
    const dbId = databaseId || freeTrailGuideData.databaseId;
    if (type === 'ticketArchive') {
      let ele = document.getElementById('td-save');
      if (ele) {
        document.getElementById('td-save').click();
      }
    } else if (type === 'templateSaveDraftOrPublish') {
      let ele = document.getElementById('publish');
      if (ele) {
        document.getElementById('publish').click();
      }
    }
    if (type === 'ticketChooseStep') {
      const data = {
        ...freeTrailGuideData,
        type: 'ticketFindDraw',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      return;
    }
    if (type === 'audit') {
      const data = {
        ...freeTrailGuideData,
        type: 'auditTemplateSelect',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      return;
    }
    if (type === 'ticketSelect' && freeTrailGuideData.guideRoles === 'reporter') {
      const data = {
        ...freeTrailGuideData,
        type: 'reporterCheckDetails',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
    }
    if (type === 'ticketCreated') {
      const data = {
        ...freeTrailGuideData,
        type: 'audit',
        link: 'audits',
      };
      stateUpdater({ type: 'userGuideData', payload: data });
      if (data.link) {
        setTimeout(
          () => {
            navigate({
              pathname: `/projects/${dbId}/${data.link}`,
            });
            window.location.reload();
          },
          userGuideData.current.nextType === 'audit' ? 500 : 0,
        );
      }
      return;
    }
    skipTuts(type);
    const data = {
      ...freeTrailGuideData,
      type: userGuideData.current.nextType,
      isViewedOnce: true,
    };
    stateUpdater({ type: 'userGuideData', payload: data });
    stateUpdater({ type: 'editMode', payload: false });
    if (userGuideData.current.link) {
      setTimeout(
        () => {
          navigate({
            pathname: `/projects/${dbId}/${userGuideData.current.link}`,
          });
          window.location.reload();
        },
        userGuideData.current.nextType === 'audit' ? 500 : 0,
      );
    }
  };

  return (
    <div className={type == 'librarySelectDrawing' ? 'user-guide_lib' : 'user-guide'} id="user-guide">
      <div className="user-guide__header">
        <div>
          {userGuideData.current.headerIcon && <img className="module-icon" src={userGuideData.current.headerIcon.default} alt="moduleIcon" loading="lazy" />}
          <p>{`${lang[userGuideData.current.headerText]}`}</p>
        </div>
        <img src={closeIcon} alt="closeIcon" onClick={() => skipTuts(type)} className="mouse-pointer" />
      </div>
      {/* Gif and Hint text */}
      <div className="user-guide__body">
        {userGuideData.current.gifUrl && <img src={userGuideData.current.gifUrl} alt="gifUrl" loading="lazy" />}
        {userGuideData.current.hint.map((hint: string) => (
          <p>{`${lang?.user_guide[hint]}`}</p>
        ))}
      </div>

      {/* Action footer buttons */}
      <div
        className={
          'user-guide__footer ' + (userGuideData.current.btnLeftLabel && userGuideData.current.btnRightLabel ? 'step-up' : userGuideData.current.btnLeftLabel ? 'space-btw' : '')
        }>
        <div
          className={
            userGuideData.current.btnLeftLabel && userGuideData.current.btnRightLabel && showButton
              ? 'btnFlex'
              : userGuideData.current.btnLeftLabel && userGuideData.current.btnNextGuide
                ? 'newGuide'
                : userGuideData.current.btnRightLabel && userGuideData.current.btnNextGuide
                  ? 'nextAndExistingGuide'
                  : 'noFlex'
          }>
          {/* Skip tuts */}
          {userGuideData.current.btnLeftLabel && showButton && (
            <button className="btn btn--cancel3" onClick={() => onNavigateBack(type)}>
              {`${lang?.user_guide[userGuideData.current.btnLeftLabel]}`}
            </button>
          )}

          {/* Continue or navigate button */}
          {userGuideData.current.btnRightLabel && (
            <button className="btn btn--cancel" onClick={() => onNavigate(type)}>
              {`${lang?.user_guide[userGuideData.current.btnRightLabel]}`}{' '}
              {(type == 'ticketChooseStep' || type == 'ticketCreated' || type == 'audit') && <img src={rightIcon} alt="rightIcon" />}
            </button>
          )}
        </div>

        {/* Stepper indicators */}
        {userGuideData.current.stepperCount && (
          <div className="stepper">
            <div className="highlight">
              {[...Array(userGuideData.current.stepperCount)].map((_e, index) => (
                <div
                  className="screen"
                  style={{
                    backgroundColor: userGuideData.current.activeStepCountIndex === index ? '#1382c5' : '#adadb1',
                  }}></div>
              ))}
            </div>
          </div>
        )}
      </div>

      {userGuideData.current.btnNextGuide && (
        <div className={'user-guide__nextGuidefooter'}>
          <button className="btn btn--cancel" onClick={() => onNavigateToNext(type)}>
            {`${lang?.user_guide[userGuideData.current.btnNextGuide]}`} <img src={rightIcon} alt="rightIcon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default React.memo(UserGuide);
