import React from 'react';
import moment from 'moment';
import { AppStateContext } from '../../';

// assets folder
import fileFilterIcon from '../../images/file_filter.svg';
import tileIcon from '../../images/Tile.svg';

type NotificationProps = {
  notifications: Object;
};

function Notification({ notifications }: NotificationProps) {
  const { language: lang } = React.useContext(AppStateContext);

  const getTimeFromTime = (time: number) => {
    var date = new Date(Number(time));
    return moment(date).format('HH:mm:ss');
  };

  const printFileName = (obj: any) => {
    switch (obj?.type) {
      case 'file':
        return (
          <div className="filename col-md-7 col-xs-7 col-sm-7">
            <img src={fileFilterIcon} alt="filter" className="notification_file_icon" />
            {obj.filename}
          </div>
        );
      case 'glacier':
        return <div className="filename col-md-7 col-xs-7 col-sm-7">{obj.filename}</div>;
      case 'tile':
        return (
          <div className="filename col-md-7 col-xs-7 col-sm-7">
            <img src={tileIcon} className="notification_file_icon" alt="" />
            {obj.filename}
          </div>
        );
      case 'user_management':
        let fileName = '';
        const dataFromFilename = obj.filename.split('|');
        if (dataFromFilename[0] === 'replace') {
          if (obj.status === 3) {
            fileName = `${lang.user_mgmnt.replaced} ${dataFromFilename[1]}`;
          } else {
            fileName = `${lang.m_txt_replace} ${dataFromFilename[1]}`;
          }
        } else {
          if (obj.status === 3) {
            fileName = `${lang.user_mgmnt.deleted} ${dataFromFilename[1]}`;
          } else {
            fileName = `${lang.m_btn_delete} ${dataFromFilename[1]}`;
          }
        }
        return <div className="filename col-md-7 col-xs-7 col-sm-7">{fileName}</div>;
      default:
        return <div className="filename col-md-7 col-xs-7 col-sm-7">{obj.filename}</div>;
    }
  };

  const ProgressState = notification => {
    return (
      <>
        <div className="col-md-12 col-xs-12 col-sm-12 progress-stat notify-tab-style" key={notification.channelID} id={notification.channelID}>
          <div className="time col-md-4 col-xs-4 col-sm-4">
            {notification.type === 'glacier' || notification.type === 'user_management' ? getTimeFromTime(notification.time) : notification.time}
          </div>
          {printFileName(notification)}
          <div className="status-image"></div>
        </div>
      </>
    );
  };

  const completedState = notification => {
    const successPDFZip = () => {
      if (notification.link.indexOf('.pdf') > 0) {
        const fName = notification.filename.slice(0, 15) + '...' + notification.link.slice(notification.link.indexOf('.pdf') - 5);
        return (
          <div className="filename col-md-7 col-xs-7 col-sm-7">
            <a href={notification.link} target="_blank" rel="noreferrer">
              {fName}
            </a>
          </div>
        );
      } else {
        const fName = notification.filename.slice(0, 13) + '...' + notification.link.slice(notification.link.indexOf('.zip') - 5);
        return (
          <div className="filename col-md-7 col-xs-7 col-sm-7">
            <a href={notification.link} target="_blank" rel="noreferrer">
              {fName}
            </a>
          </div>
        );
      }
    };
    return (
      <div className="col-md-12 col-xs-12 col-sm-12 success-stat notify-tab-style" key={notification.channelID} id={notification.channelID}>
        <div className="time col-md-4 col-xs-4 col-sm-4">
          {notification.type === 'glacier' || notification.type === 'user_management' ? getTimeFromTime(notification.time) : notification.time}
        </div>
        {!notification.link && (
          <>
            {printFileName(notification)}
            <div className="success-map-image"></div>
          </>
        )}
        {notification.link && (
          <>
            {successPDFZip()}
            <div className="success-image"></div>
          </>
        )}
      </div>
    );
  };

  const ErrorState = notification => {
    return (
      <div id={notification.channelID} key={notification.channelID} className="col-md-12 col-xs-12 col-sm-12 error-stat notify-tab-style">
        <div className="time col-md-4 col-xs-4 col-sm-4">
          {notification.type === 'glacier' || notification.type === 'user_management' ? getTimeFromTime(notification.time) : notification.time}
        </div>
        {notification.type && notification.type === 'file' ? (
          <div className="filename col-md-7 col-xs-7 col-sm-7">
            <img src={notification.type === 'file' ? fileFilterIcon : tileIcon} className="notification_file_icon" alt="" />
            {notification.filename}
          </div>
        ) : (
          <div className="filename col-md-7 col-xs-7 col-sm-7">{notification.filename}</div>
        )}
        <div className="error-image"></div>
      </div>
    );
  };

  /**
   *
   * @param obj
   * @returns
   * 1,2 created, accepted
   * 3 completed
   * 4 failed
   */
  const statusHtml = obj => {
    switch (obj?.status) {
      case 1:
      case 2:
        if (obj.channelID) return ProgressState(obj);
        break;
      case 3:
        if (obj.channelID) return completedState(obj);
        break;
      case 4:
        if (obj.channelID) return ErrorState(obj);
        break;
    }
  };

  return (
    <>
      <div className="notification-wrapper">
        <div className="modal-header notify-header">
          <span className="notify-tabs">{lang.m_txt_notifications}</span>
        </div>
        <div className="modal-body">
          <h4
            className="tab-style"
            style={{
              display: Object.keys(notifications).length ? 'none' : 'block',
            }}>
            {lang.m_txt_no_notifi}
          </h4>
          <hr />
          {Object.keys(notifications).map(key => notifications[key].isShown && statusHtml(notifications[key]))}
        </div>
      </div>
    </>
  );
}

export default Notification;
