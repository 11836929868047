import { ParticipantsModel } from './participant.model';
import { SettingsModel } from './settings.model';
import { CopiedProject } from './copiedproject.model';
import { Content, ContentModifier } from './content';
import { ProjectDates } from './dates';
import { Location } from './location.model';
import { Operation, Timeline } from './operation';
import { Connector, IConnector } from './connector.model';

export interface IProject {
  _attachments?: any;
  _id?: string | null;
  _rev?: string | null;
  administrators: any[];
  archived?: string | null;
  content: Content | ContentModifier;
  contractId?: string;
  copiedFrom: CopiedProject | null;
  dates: ProjectDates;
  defaultResources: any[];
  doctrine_metadata: {};
  location: Location;
  name: string;
  invoicedDate?: string | null;
  isDemoProject: boolean;
  metaData: any | null;
  operation?: Operation[];
  participants: ParticipantsModel;
  referenceName: string;
  isEmailEnabled: boolean;
  isProofMandatory: boolean;
  internalPurchaseNumber: string;
  settings: SettingsModel;
  timeline: Timeline[];
  type: string;
  customFields: ICustomFields[];
}

export class ProjectModel implements IProject {
  _attachments?: any;
  _id?: string;
  _rev?: string;
  administrators: any[];
  archived?: any;
  content: ContentModifier;
  contractId?: string;
  copiedFrom: CopiedProject;
  couchDbId: string;
  dates: ProjectDates;
  dbName?: string;
  isGlacier?: string;
  defaultResources: any[];
  doctrine_metadata: {};
  location: Location;
  name: string;
  isDemoProject: boolean;
  operation?: Operation[];
  participants: ParticipantsModel;
  referenceName: string;
  isEmailEnabled: boolean;
  isProofMandatory: boolean;
  internalPurchaseNumber: string;
  settings: SettingsModel;
  timeline: Timeline[];
  type: string;
  invoicedDate?: string | null;
  metaData: any;
  customFields: ICustomFields[];
  constructor(data: any = {}) {
    this._id = data._id || null;
    this._rev = data._rev || null;
    this._attachments = data._attachments || {};
    this.administrators = data.administrators || [];
    this.archived = data.archived || null;
    this.content = data.content || new ContentModifier();
    this.contractId = data.contractId || data.contract || '';
    this.copiedFrom = data.copiedFrom || null;
    this.couchDbId = data.couchDbId || '';
    this.dates = data.dates || new ProjectDates();
    this.defaultResources = data.defaultResources || [];
    this.customFields = data.customFields || [];
    this.doctrine_metadata = data.doctrine_metadata || {
      indexed: true,
      indexes: ['name'],
    };
    this.location = data.location || new Location();
    this.name = data.name || '';
    this.operation = data.operation || [new Operation()];
    this.participants = data.participants || new ParticipantsModel();
    this.referenceName = data.referenceName || '';
    this.isEmailEnabled = data.isEmailEnabled === false ? false : true;
    this.internalPurchaseNumber = data.internalPurchaseNumber || '';
    this.isDemoProject = data.isDemoProject || false;
    this.isProofMandatory = data.isProofMandatory || false;
    this.settings = data.settings || new SettingsModel();
    this.type = data.type || 'IB.EdBundle.Document.Project';
    this.invoicedDate = data.invoicedDate || null;
    this.metaData = data.metaData || new Connector();
  }
}
export interface IProjectError {
  name: boolean;
  contract: boolean;
  accountable: boolean;
  support: boolean;
  informed: boolean;
  consulted: boolean;
  referenceName: boolean;
  internalPurchaseNumber: boolean;
  customField: boolean;
  location: boolean;
}
export class ProjectError implements IProjectError {
  name: boolean;
  contract: boolean;
  accountable: boolean;
  support: boolean;
  informed: boolean;
  consulted: boolean;
  referenceName: boolean;
  internalPurchaseNumber: boolean;
  customField: boolean;
  location: boolean;
  constructor(data: any = {}) {
    this.name = data.name || false;
    this.contract = data.contract || false;
    this.accountable = data.accountable || false;
    this.support = data.support || false;
    this.informed = data.informed || false;
    this.consulted = data.consulted || false;
    this.referenceName = data.referenceName || false;
    this.internalPurchaseNumber = data.internalPurchaseNumber || false;
    this.location = data.location || false;
    this.customField = data.customField || false;
  }
}
export interface IProjectState {
  projectForm: IProject;
  userList: Array<any>;
  selectedModules: Array<any>;
  addedAndRevokedEmail: any;
  contractProjectsList: Array<any>;
  projectImage: string | null;
  projectLogo: string | null;
  errors: IProjectError;
  connector: IConnector;
  canSubmit: boolean;
  canArchive: boolean;
  originalProjectImage: string;
  originalProjectLogo: string;
  selectedContractProject: any;
  copyProjectButtonClicked: string | boolean;
}

export type ProjectParams = {
  databaseId: string;
  projectId: string;
};

export const intialProjectFormReducerValue = {
  projectForm: new ProjectModel(),
  userList: [],
  selectedModules: [],
  projectImage: null,
  projectLogo: null,
  errors: new ProjectError(),
  canSubmit: false,
  connector: new Connector(),
  originalProjectImage: '',
  originalProjectLogo: '',
  selectedContractProject: '',
  copyProjectButtonClicked: '',
  contractProjectsList: [],
  addedAndRevokedEmail: { informed: { removedEmails: [], addedEmails: [] }, consulted: { removedEmails: [], addedEmails: [] } },
  canArchive: false,
};

export interface IProjectAppState {
  isGlacier: boolean;
  endDate: null | string;
  roles: Roles;
  contract: string;
  remoteLink: string;
  geomap: boolean;
  isEmailEnabled: boolean;
  archived: null | string;
  thumbImage: string;
  location: string;
  couchDbId: string;
  projectName: string;
  projectId: string;
  referenceName: string;
  internalPurchaseNumber: string;
}

export interface Roles {
  accountable: boolean;
  rci: boolean;
  reporter: boolean;
  support: boolean;
}

export interface ICustomFields {
  id: string;
  label: string;
  type: 'freetext' | 'date' | 'numeric';
}
export interface ICustomFieldsValue extends ICustomFields {
  value: string;
}
export class CustomFieldsObj implements ICustomFields {
  id: string;
  label: string;
  type: 'freetext' | 'date' | 'numeric';
  constructor(data: any = {}) {
    this.id = data.id || '';
    this.label = data.label || '';
    this.type = data.type || 'freetext';
  }
}
